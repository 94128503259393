
import Valuation from "~/components/commercial/Valuations.vue";
import ValuationProperties from "~/components/commercial/ValuationProperties.vue";
import LandValuation from "~/components/commercial/LandValuations.vue";
import SellingPrice from "~/components/commercial/SellingPrices.vue";
import { getPropertyCommercialQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import { getSelectedTabFromUrl } from "~/plugins/common-fns.js";

export default {
  apollo: {
    commercial: getPropertyCommercialQuery,
  },

  components: {
    Valuation,
    LandValuation,
    SellingPrice,
    ValuationProperties,
  },

  props: {
    commercial: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      active: getSelectedTabFromUrl(this),
    };
  },

  computed: {
    sellingPrices() {
      if (this.commercial && this.commercial.danishLandRegister && this.commercial.danishLandRegister.sellingPrices) {
        return this.commercial.danishLandRegister.sellingPrices;
      }
      return null;
    },

    valuations() {
      if (this.commercial && this.commercial.valuations) {
        return this.commercial.valuations;
      } else return [];
    },

    valuationProperties() {
      if (this.commercial?.valuationProperties) {
        return this.commercial?.valuationProperties;
      } else return [];
    },

    landValuations() {
      if (this.commercial && this.commercial.valuations) {
        let res = [];
        this.commercial.valuations.forEach((valuation) => {
          if (!valuation.landValuations) {
            return;
          }
          valuation.landValuations.forEach((landValuation) => {
            res.push({...landValuation, year: valuation.year, valuationDate: valuation.valuationDate});
          });
        });

        return res;
      } else return [];
    },
  },

  watch: {
    "$route.query.activeTab": function (newValue) {
      this.active = parseInt(newValue, 10) ?? 0;
    },
  },

  methods: {
    amplitudeLogEvent(triggerEvent) {
      triggerEvent = "View commercial " + triggerEvent;
      this.$amplitude.track({ event_type: triggerEvent });
    },

    selectTabAndLogAmplitudeEvent(id, event) {
      this.$router.push({
        path: this.$route.path,
        query: {
          activeTab: id,
        },
      });

      this.$amplitude.track({ event_type: `View commercial ${event}` });
    },
  },
};
