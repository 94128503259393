
import { group_digits_in_number, filter_valuations } from "~/plugins/common-fns.js";
import { filter } from "~/composables/useFilter";

export default {
  components: {},
  props: {
    valuations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      datacollection: null,
    };
  },
  computed: {
    filter() {
      return filter;
    },

    sortedValuations() {
      if (this.valuations) {
        return filter_valuations(this.valuations);
      }
      return null;
    },
    years() {
      if (this.sortedValuations) {
        return this.sortedValuations
          .filter((x) => x.caseNumber == 1)
          .map((x) => x.year)
          .slice(0, 5)
          .reverse();
      }
      return null;
    },
    rows() {
      if (this.sortedValuations) {
        let dates = [];
        // let types = [];
        // let specifications = [];
        let amounts = [];
        let unitPrices = [];
        let calculatedPrices = [];
        this.sortedValuations
          .filter((x) => x.caseNumber == 1)
          .slice(0, 5)
          .reverse()
          .forEach((x) => {
            dates.push(this.$moment(x.valuationDate).format("YYYY-MM-DD"));
            // types.push(x.text);
            // specifications.push(x.priceText);
            amounts.push(x.area);
            unitPrices.push(x.unitPrice);
            calculatedPrices.push(x.valuation);
          });
        return {
          DATE: dates,
          // "Type": types,
          // "Specification": specifications,
          C_SPEC_SQM: amounts,
          C_SPEC_SQMPRICE: unitPrices,
          C_SPEC_PRICE: calculatedPrices,
        };
      }
      return null;
    },
    allYears() {
      if (this.sortedValuations) {
        return this.sortedValuations.map((x) => x.year);
      }
      return null;
    },
    maxYears() {
      if (this.allYears) {
        return this.allYears.length;
      }
      return 5;
    },
  },

  mounted() {
    this.fillData();
  },
  created() {
    this.fillData();
  },
  methods: {
    group_digits_in_number: group_digits_in_number,
    fillData() {
      if (this.sortedValuations) {
        this.datacollection = {
          xaxis: {
            categories: this.sortedValuations
              .filter((x) => x.caseNumber == 1)
              .map((x) => x.year)
              .reverse(),
          },
          series: [
            {
              name: this.$t("C_LANDVALUE"),
              data: this.sortedValuations
                .filter((x) => x.caseNumber == 1)
                .map((x) => x.valuation)
                .reverse(),
            },
          ],
        };
      }
    },
  },
};
