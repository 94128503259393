
export default {
  props: {
    sellingPrices: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t("DATE"), value: "date", sortable: true },
        { text: this.$t("C_TRANS_TYPE"), value: "documentType", sortable: true },
        { text: this.$t("C_TRANS_TEXT"), value: "text", sortable: true },
      ],
    };
  },
  computed: {},
  methods: {
    displayDocumentType(text) {
      if (text === null) {
        return this.$t("VALUE_NOT_REGISTERED");
      }

      if (text != null) {
        switch (text.toLowerCase()) {
          case "skoede":
            return this.$t("C_TRANS_DEED");
          case "endeligtskoede":
            return this.$t("C_TRANS_FINAL_DEED");
          case "andenadkomst":
            return this.$t("C_TRANS_DIF_CLAIM");
          default:
            return text || this.$t("VALUE_NOT_REGISTERED");
        }
      }
    },
    replaceNewLineWithBr(text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    insertDanishSpecialCharacters(text) {
      if (text) {
        return text.replace("oe", "ø").replace("aa", "å").replace("ae", "æ");
      }
      return this.$t("VALUE_NOT_REGISTERED");
    },
    modifyText(text) {
      if (text.includes("Købesum")) {
        text = text.replace("Købesum", this.$t("C_TRANS_PURCHASE_PRICE"));
      }
      if (text.includes("Adkomsthavere")) {
        text = text.replace("Adkomsthavere", this.$t("C_TRANS_CLAIMS"));
      }
      if (text.includes("Ejerandel")) {
        text = text.replace("Ejerandel", this.$t("C_TRANS_OWNERS_SHARE"));
      }
      text = this.replaceNewLineWithBr(text);
      return text;
    },
  },
};
