import { render, staticRenderFns } from "./Commercial.vue?vue&type=template&id=ce41895e"
import script from "./Commercial.vue?vue&type=script&lang=js"
export * from "./Commercial.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TabBoxItem: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/TabBox/TabBoxItem.vue').default,TabBox: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/TabBox/TabBox.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,ValuationProperties: require('/app/components/commercial/ValuationProperties.vue').default,EmptyState: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/EmptyState/EmptyState.vue').default,CompositionLayoutSingle: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default})
