
import { getPropertyCommercialQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import Commercial from "../components/Commercial.vue";

export default {
  title() {
    return `${this.$t("MENU_COMMERCIAL")}`;
  },
  apollo: {
    commercial: getPropertyCommercialQuery,
  },
  components: {
    Commercial,
  },
  computed: {
    loading: function () {
      return this.commercial == null || this.$apollo.queries.commercial.loading;
    },
  },
};
